import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';

// Vant
import Vant from 'vant';
import 'vant/lib/index.css';

// import vConsole from '@/utils/vconsole.js'
// ElementPlus
// import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';

import VueClipboard from 'vue-clipboard2'
// import VueWechatTitle  from 'vue-wechat-title'
//
import vueSeamlessScroll from 'vue-seamless-scroll'

// rem适配
import "./utils/remConfig";

const app = createApp(App);


app
    .use(router)
    .use(store)
    // .use(vConsole)
    .use(Vant)
    .use(vueSeamlessScroll)
    .use(VueClipboard)
  // .use(VueWechatTitle )
  .mount('#app')
