import {createRouter, createWebHistory} from 'vue-router';

const routers = [
    {
        path: '/',
        component: () => import('../views/index/index.vue'),
        mata: {
            title: '招采公告'
        }
    },
    {
        path: '/register',
        component: () => import('../views/login/register.vue'),
        mata: {
            title: '注册'
        }
    },
    {
        path: '/login',
        component: () => import('../views/login/index.vue'),
        mata: {
            title: '登录'
        }
    },
    {
        path: '/quotationList',
        component: () => import('../views/quotedPrice/quotationList.vue'),
        mata: {
            title: '报价书'
        }
    },
    {
        path: '/quotationDetails',
        component: () => import('../views/quotedPrice/quotationDetails.vue'),
        mata: {
            title: '报价确认'
        }
    },
    {
        path: '/quotedPrice',
        component: () => import('../views/quotedPrice/quotedPrice.vue'),
        mata: {
            title: '填写报价'
        }
    },

    {
        path: '/stoneDetail',
        name: 'stoneDetail',
        component: () => import('../views/quotedPrice/stoneDetail.vue'),
        mata: {
            title: '用料标准'
        }
    },
    {
        path: '/stoneList',
        name: 'stoneList',
        component: () => import('../views/quotedPrice/stoneList.vue'),
        mata: {
            title: '切换石种'
        }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes: routers,
});

// router.beforeEach((to,from) =>{
//     console.log(to)
//     console.log(from)
//     // 路由发生变化修改页面title
//     if (to.path == '/matchList') {
//         window.document.title = '材料运用角斗场';
//     }
// })
// window.document.title = to.meta.title

// 导出路由
export default router;
