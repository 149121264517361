import { createStore } from 'vuex'

export default createStore({
    state: {
        // 放置变量所用
    },
    mutations: {
        // 获取set方法
    },
    actions: {
    },
    modules: {
    }
})
