<template>
  <div id="app">
    <router-view/>
  </div>
</template>


<script>
import {defineComponent, onMounted} from 'vue';
import {getCurrentUser} from '@/utils/interaction'
import {LsControl} from '@/utils/control'
import {generateQpToken} from '@/api/login'
import {versions} from '@/utils/toolsValidate'

const {Session} = require("./utils/storage");
export default defineComponent({
  name: 'App',
  setup() {
    onMounted(() => {
      const dev = versions();
      if (dev.isApp) {
        window.LsControl = LsControl
        getCurrentUser().then(res => {
          if (res == 'other') return
          let userInfo = JSON.parse(res);
          let obj = {
            userId: userInfo.USER_CODE,
            phone: userInfo.PHONE,
            userName: userInfo.USER_NAME,
            orgName: userInfo.SHOP_NAME,
            orgId: userInfo.OFFICE_CODE,
          }
          generateQpToken(obj).then(res => {
            if (res.result) {
              Session.set('token', res.data);
              console.log('token', res.data)
              console.log('sessionToken', Session.get('token'))
            }
          })
        })
      }
    })
    return {
      onMounted
    }
  }
})
</script>

<style lang="less">
#app {
  font-family: PingFangSC-Medium, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}

p, span {
  list-style: none;
  margin: 0;
  padding: 0;
}

.van-nav-bar__content {
  height: 0.46rem !important;
  line-height: 0.45rem !important;
}

.van-nav-bar .van-icon {
  color: #333333 !important;
  font-size: 0.17rem !important;
}

.van-nav-bar__title {
  font-size: 0.17rem !important;
  font-weight: 500 !important;
}

//.toastTip{
//  background: #3967A2 !important;
//  opacity: 0.6 !important;
//}
.van-toast {
  font-size: .14rem;
  background: rgba(57, 103, 162, 0.6) !important;
  //opacity: 0.6 !important;
}

.m-header {
  width: 100%;
  background: #FFFFFF;
  height: 0.88rem;
  font-size: 0.36rem;
  font-weight: 800;
  color: #333333;
  line-height: 0.88rem;
  text-align: center;
  position: relative;
}

.m-header-icon {
  position: absolute !important;
  top: 50%;
  margin-top: -.18rem;
  left: .3rem;
  font-size: .35rem;
}

.el-message {
  min-width: 1.8rem !important;
  height: 0.5rem;
  //width:1.8rem !important;
  padding: 0.12rem !important;
}

.el-message__icon {
  margin-right: 10px;
  width: 0.3rem !important;
  height: 0.3rem !important;
}

.van-field__error-message {
  position: absolute;
  /*top: 50%;*/
  font-size: .26rem;
  /*margin-top: -.26rem;*/
  margin-top: -.20rem;
}

.van-list__finished-text {
  background: #F4F5F7;
}

.van-image-preview__image img {
  background: #FFFFFF;
}

.el-message-box {
  position: absolute;
  top: 40%;
  width: 6.1rem !important;
  left: .7rem;
}

.seamless-warp2 {
  overflow: hidden;
  height: .43rem;
  width: 100%;

  ul.item {
    height: .43rem;
    width: 150%;
    display: flex;
    flex-wrap: wrap;
    font-size: 0.24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 0.43rem;
    margin-bottom: .2rem;

    li {
      height: .43rem;
      float: left;
      //display: flex;
      //flex-direction: column;
      margin-right: 10px;
    }
  }

  .items {
    li {
      margin-right: 0 !important;
      margin-left: 10px;
    }
  }
}
</style>
