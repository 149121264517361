/**
 * 分享
 * @param val 当前值字符串
 * @returns 返回 true: 手机号码正确
 */
// import {showShare} from "../api/login";

// export function webShare(type, serverKey, webKey) {//web分享方法
// 	// webKey：URL拼接携带参数
// 	// serverKey：访问服务器参数
// 	// type : 传进页面的制定码数，唯一
// 	// type：0：项目详情-概览，1：项目用料详情,2:项目详情-用料,3:手动规划切割记录,4:项目详情-记录,5:设备切割记录,6:员工绩效,7:员工切割记录,8,首页切割记录,9:已领料分享,10:获取工程板卡片信息
// 	// selectMap : 页面路由 请求url
//
// 	let selectMap = [
// 		["detail", "csShowDetail/getShowDetailInfo"], // 彩平秀详情
// 		["organization", "csOrgFans/getShowOrgInfo"], // 彩平秀-组织
//
// 	];
// 	if(Number(type) >= 16 && Number(type) < 20) {
// 		showShare(serverKey, selectMap[type][1]).then(res => {
// 			let postData = JSON.parse(JSON.stringify(res.data));
// 			postData.jointUrl = `/${process.env.VERSION}/${selectMap[type][0]}?`;
// 			webKey.mes = sessionStorage.getItem('mes')
// 			for (const i in webKey) {
// 				postData.jointUrl += i + "=" + webKey[i] + "&";
// 			}
// 			postData.jointUrl = postData.jointUrl.substring(0, postData.jointUrl.length - 1);
// 			console.log(postData)
// 			console.log(postData.jointUrl.split("&"))
// 			if (/(iPhone|iPad|iPod|iOS|Mac)/i.test(navigator.userAgent)) {
// 				window.webkit.messageHandlers.share.postMessage(postData);
// 			} else if (/(Android)/i.test(navigator.userAgent)) {
// 				window.android.shareWechat(JSON.stringify(postData));
// 			}
// 		});
// 	}
// }
/**
 * 手机号码
 * @param val 当前值字符串
 * @returns 返回 true: 手机号码正确
 */
export function verifyPhone(val) {
	// false: 手机号码不正确
	if (!/^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(19[0-9]{1})|(18[0-9]{1}))+\d{8})$/.test(val)) return false;
	// true: 手机号码正确
	else return true;
}
export function formatDate(params ) {
	let defalutParams = {
		date: new Date(),
		formatStr: 'yyyy-MM-dd HH:mm:ss'
	}
	params = { ...defalutParams, ...params };
	let date = params.date;
	let formatStr = params.formatStr;
	// 传入日期字符串 - 转成时间戳 - 转成标准时间
	let timeStamp = new Date(date).getTime();
	date = new Date(timeStamp);
	formatStr = formatStr.replace(new RegExp('yyyy'), `${date.getFullYear()}`);
	const month = date.getMonth() + 1;
	formatStr = formatStr.replace(new RegExp('MM'), `${month > 9 ? month : '0' + month}`);
	const day = date.getDate();
	formatStr = formatStr.replace(new RegExp('dd'), `${day > 9 ? day : '0' + day}`);
	const hour = date.getHours();
	formatStr = formatStr.replace(new RegExp('HH'), `${hour > 9 ? hour : '0' + hour}`);
	const min = date.getMinutes();
	formatStr = formatStr.replace(new RegExp('mm'), `${min > 9 ? min : '0' + min}`);
	const sec = date.getSeconds();
	formatStr = formatStr.replace(new RegExp('ss'), `${sec > 9 ? sec : '0' + sec}`);
	return formatStr;
}

/**
 * 获取设备
 * @param val 当前值字符串
 * @returns 返回 true: 手机号码正确
 */
export function devType() {
	let agent = navigator.userAgent.toLowerCase();
	let result = {
		device: function () {
			console.log(agent)
			if (/windows/.test(agent)) {
				return 'PC';
			} else if (/iPhone|iPad|iPod|iOS|Mac|iphone/.test(agent) && /mobile/.test(agent)) {
				return 'IOS';
			} else if (/android/.test(agent) && /mobile/.test(agent)) {
				return 'ANDROID';
			} else if (/linux/.test(agent)) {
				return 'PC';
			} else {
				return 'WEB';
			}
		}(),
	};
	return result.device;
}

/**
 * 判断当前平台
 * @param val 当前值字符串
 * @returns 返回 true: 手机号码正确
 */
export function versions() {
	var u = navigator.userAgent;
	return {
		//移动终端浏览器版本信息
		trident: u.indexOf("Trident") > -1, //IE内核
		presto: u.indexOf("Presto") > -1, //opera内核
		webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
		gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
		mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
		mac: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
		android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或uc浏览器
		iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
		iPad: u.indexOf("iPad") > -1, //是否iPad
		ios:
			!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) ||
			u.indexOf("iPhone") > -1 ||
			u.indexOf("iPad") > -1,
		webApp: u.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
		isApp: u.indexOf("Ls_Android") > -1 || u.indexOf("Ls_iPhone") > -1, //是否链石应用
		isAndroid: u.indexOf("Ls_Android") > -1, //链石应用安卓版
		lsIPhone: u.indexOf("Ls_iPhone") > -1, //链石应用iPhone版
		weixin: u.indexOf("MicroMessenger") > -1, //微信
		tiktok: u.indexOf("ToutiaoMicroApp") > -1, //头条小程序
	};
}


