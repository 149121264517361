import axios from 'axios';
import {ElMessage} from "element-plus/lib/components"
import {Session} from './storage';

// 配置新建一个 axios 实例
const service = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	timeout: 60000,
	headers: {'Content-Type': 'application/json'},
});

// 添加请求拦截器
service.interceptors.request.use(
	(config) => {
		// 在发送请求之前做些什么 token
		if (Session.get('token')) {
			config.headers.common['Authorization'] = `${Session.get('token')}`;
		}
		return config;
	},
	(error) => {
		// 对请求错误做些什么
		return Promise.reject(error);
	},
);

// 添加响应拦截器
service.interceptors.response.use(
	(response) => {
		// 对响应数据做点什么
		const res = response.data;
		console.log('asdsadsads', res)
		if (!res.result) {
			if (res.code === 3) {
				// 频繁请求-警告
				ElMessage.warning(res.msg);
			} else if (res.code === 570687) {
				// 不做操作
			} else {
				ElMessage.error(res.msg);
			}
			setTimeout(() => {
				// 5:	访问未授权; 6: 您没有权限进行该操作; 15:Token 已过期; 16:TOKEN错误,请重新获取 17:密码错误
				if (res.code === 5 || res.code === 6 || res.code === 15 || res.code === 16) {
					// Session.clear(); // 清除浏览器全部临时缓存
					// localStorage.setItem('redirectUrl',window.location.href)
					window.location.href = '/login'; // 去登录页
				}
			}, 1000);
			return Promise.reject(res);
		} else {
			if (res.code === 14) {
				let config = response.config;
				const token = res.data.token;
				Session.set('token', token);
				config.headers['Authorization'] = token;
				return axios(config).then(function(res) {
					return res.data;
				});
			} else {
				return response.data;
			}
		}
	},
	(error) => {
		// 对响应错误做点什么
		if (error.message.indexOf('timeout') != -1) {
			ElMessage.error('网络超时');
		} else if (error.message == 'Network Error') {
			ElMessage.error('网络连接错误');
		} else {
			if (error.response.data) {
				ElMessage.error(error.response.statusText);
			} else {
				ElMessage.error('接口路径找不到');
			}
		}
		return Promise.reject(error);
	},
);

// 导出 axios 实例
export default service;
