import {devType} from '@/utils/toolsValidate'
import {Date} from "core-js";

const dev = devType();
//获取app 用户信息
const getCurrentUser = () => {
    return new Promise((resolve) => {
        // console.log(LsControl)
        if (dev == 'ANDROID') {
            resolve(window.control.getCacheValue('CurrentUser'));
        } else if (dev == 'IOS') {
            let timestamp = Date.now();
            let data = {
                resolveId: timestamp + ~~(Math.random() * 10000),
                key: "CurrentUser",
                callback: "window.LsControl.iosCallback", //ios处理成功后回调H5的方法
            };
            window.webkit.messageHandlers.getCacheValue.postMessage(
                JSON.stringify(data)
            );
            // eslint-disable-next-line no-undef
            LsControl.asyncMap.setMap(data.resolveId, resolve);
        } else {
            resolve('other')
        }
    })
}
// 安卓上传图片
const pickImagesAndUpload = (options) => {
    console.log('进入方法')
    window.control.pickImagesAndUploadWithArgus(
        options.uploadUrl,
        options.jsCallback,
        options.maxNum,
        options.params
    );
}
export {
    getCurrentUser,
    pickImagesAndUpload
}
