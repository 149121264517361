import instance from "../../utils/request";
// TODO 短信登录-用户相关
export const login = (params) => {
    return instance({
        url: "/h5/qpUser/login",
        method: 'post',
        data: params,
    })
}
export const sendSmsVerifyCode = (params) => {
    return instance({
        url: "/h5/qpUser/sendSmsVerifyCode",
        method: 'post',
        data: params,
    })
}

export const logout = (params) => {
    return instance({
        url: "/h5/qpUser/logout",
        method: 'post',
        data: params,
    })
}
// TODO 生成报价用户token
export const generateQpToken = (params) => {
    return instance({
        url: "/h5/qpUser/generateQpToken",
        method: 'post',
        data: params,
    })
}

export default {
    login,
    sendSmsVerifyCode,
    logout,
};
