// eslint-disable-next-line no-unused-vars
var LsControl = Object.freeze({
    // 异步回调方法列表
    asyncMap: {
        mapList: {},
        // add resolve by id
        setMap: function (id, resolve) {
            this.mapList[id] = resolve;
        },
        execute: function (id, theArgs) {
            try {
                // eslint-disable-next-line no-unused-vars
                let resolveFunc = this.mapList[id];

                let argString = "";
                if (theArgs.length === 1) {
                    // resolveFunc(theArgs[0]);
                    console.log(theArgs[0])
                    eval(`resolveFunc('${theArgs[0]}')`);
                } else {
                    theArgs.forEach((arg) => {
                        argString.concat("'" + arg + "',");
                    });
                    argString = argString.substring(0, argString.length - 1);
                    eval(`resolveFunc(${argString})`);
                }
                this._delMap(id);
            } catch (e) {
                console.log(e)
            }
        },
        _delMap: function (id) {
            if (id) {
                delete this.mapList[id];
            }
        },
    },

    /**
     * 获取用户信息 回调
     */
    iosCallback(resolveId, ...theArgs) {
        console.log('进入回调')
        console.log(resolveId)
        console.log(theArgs)
        this.asyncMap.execute(resolveId, theArgs);
    },
});

export {
    LsControl
}
